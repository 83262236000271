import logo from './logo.svg';
import './App.css';
import { useLocation } from 'react-router-dom';
import { AppRouter } from './router/AppRouter';
import Footer from './components/Footer/Footer';

function App() {

const location = useLocation();


  return (
    <div className="appContainer">
    {/* Contenido de tu aplicación */}
    <div> {/* Contenedor para el contenido */}
      {/* Aquí va el contenido principal de tu aplicación */}
      <AppRouter></AppRouter>
    </div>

    <Footer />
  </div>
  );
}

export default App;
