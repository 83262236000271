import React from 'react'
import { Header } from '../../../components/Header/Header'

const Home = () => {
    return (
        <>
            <Header></Header>               
        </>
    )
}

export default Home