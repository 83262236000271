import React from 'react'
import styles from './PoliticaPrivacidad.module.css'; // Importa tu archivo CSS

export const PoliticaPrivacidad = () => {
    return (
        <div className={styles.softwarePolicyContainer}>
            <h1>Política de Uso de Software de Baaxal Studios</h1>

            <h2>Términos y Condiciones de Uso</h2>

            <section>
                <h3>1. Aceptación de los Términos</h3>
                <p>Al utilizar el software proporcionado por Baaxal Studios ("el Software"), usted acuerda estar vinculado por estos términos y condiciones. Si no está de acuerdo con estos términos, absténgase de utilizar el Software.</p>
            </section>

            <section>
                <h3>2. Propiedad Intelectual</h3>
                <p>Todo el contenido, marcas, logos y demás material en el Software son propiedad exclusiva de Baaxal Studios y
                    están protegidos por las leyes de derechos de autor, marcas comerciales y otras leyes de propiedad intelectual.</p>
            </section>

            <section>
                <h3>3. Uso del Software</h3>
                <p>El Software se proporciona "tal cual" para su uso personal o comercial. No debe ser modificado,
                    copiado, distribuido, vendido o utilizado de manera que no esté expresamente autorizada por Baaxal Studios.</p>
            </section>

            <section>
                <h3>4. Limitación de Responsabilidad</h3>
                <p>Baaxal Studios no será responsable por daños directos, indirectos, incidentales, especiales,
                    consecuentes o ejemplares, incluyendo pero no limitado a, daños por pérdida de beneficios, buena voluntad, uso, datos u otras pérdidas intangibles, que resulten del uso o la imposibilidad de usar el Software.</p>
            </section>

            <section>
                <h3>5. Exclusión de Garantías</h3>
                <p>Baaxal Studios no garantiza que el Software será ininterrumpido, oportuno, seguro,
                    libre de errores o que los resultados obtenidos serán exactos o confiables. El uso del Software es bajo su propio riesgo.</p>
            </section>

            <section>
                <h3>6. Indemnización</h3>
                <p>Usted acuerda indemnizar y eximir de responsabilidad a Baaxal Studios, sus filiales, directores,
                    agentes, empleados y socios, de cualquier reclamación o demanda, incluyendo honorarios razonables de abogados, hecha por cualquier tercero debido a o derivada de su uso del Software.</p>
            </section>

            <section>
                <h3>7. Modificaciones de los Términos</h3>
                <p>Baaxal Studios se reserva el derecho de modificar estos términos y condiciones en cualquier momento.
                    Su uso continuado del Software constituye su aceptación de dichos cambios.</p>
            </section>

            <section>
                <h3>8. Legislación Aplicable y Jurisdicción</h3>
                <p>Estos términos se regirán e interpretarán de acuerdo con las leyes de México.
                    Cualquier disputa relacionada con estos términos estará sujeta a la jurisdicción exclusiva de los tribunales de México.</p>
            </section>

            <section>
                <h3>9. Acuerdo Completo</h3>
                <p>Estos términos constituyen el acuerdo completo entre usted y Baaxal Studios con respecto al uso del Software y
                    reemplazan cualquier acuerdo previo entre usted y Baaxal Studios en relación con el Software.</p>
            </section>

            <section>
                <h3>10. Contacto</h3>
                <p>Si tiene preguntas sobre estos términos, por favor contáctenos en baaxal.desarrollo@gmail.com.</p>
            </section>


            {/* ========================================*/}

            <h1>Política de Privacidad de Baaxal Studios</h1>

            <h2>En Baaxal Studios, respetamos la privacidad de nuestros usuarios y estamos comprometidos con la protección de sus datos personales. Esta política de privacidad explica cómo recopilamos, usamos, compartimos y protegemos la información en relación con nuestro software y servicios.</h2>


            <section>
                <h3>1. Aceptación de la Política de Privacidad</h3>
                <p>Al acceder y utilizar nuestro software, usted acepta las prácticas descritas en esta política de privacidad. Si no está de acuerdo con esta política, por favor no utilice nuestro software.</p>
            </section>


            <section>
                <h3>2. Información que Recopilamos</h3>
                <p>Podemos recopilar datos personales y sensibles, incluyendo, pero no limitado a:
                    <br></br>
                    Información de contacto como nombre, dirección de correo electrónico y número de teléfono.
                    Datos de ubicación para ofrecer servicios basados en la localización.
                    Información financiera para procesar pagos.
                    Datos de uso y preferencias para mejorar la experiencia del usuario.</p>
            </section>


            <section>
                <h3>3. Uso de la Información</h3>
                <p>Utilizamos la información recopilada para:
                    <br></br>
                    Proporcionar, mantener y mejorar nuestros servicios.
                    Procesar transacciones y enviar notificaciones relacionadas con el uso del software.
                    Comunicarnos con usted sobre productos, servicios, ofertas y promociones.</p>
            </section>


            <section>
                <h3>4. Compartir Información con Terceros</h3>
                <p>No compartimos su información personal con terceros, excepto en los siguientes casos:
                <br></br>
                    Con su consentimiento explícito.
                    Para cumplir con la ley o proteger nuestros derechos.
                    Con proveedores de servicios que trabajan en nuestro nombre bajo acuerdos de confidencialidad.</p>
            </section>


            <section>
                <h3>5. Medidas de Seguridad</h3>
                <p>Implementamos medidas de seguridad técnicas y organizativas para proteger sus datos personales contra el acceso no autorizado, alteración, divulgación o destrucción.</p>
            </section>

            <section>
                <h3>6. Conservación y Eliminación de Datos</h3>
                <p>Conservamos su información personal solo durante el tiempo necesario para los fines establecidos en esta política de privacidad y eliminamos los datos personales de manera segura cuando ya no son necesarios.</p>
            </section>

            <section>
                <h3>7. Contacto para Consultas de Privacidad</h3>
                <p>Si tiene preguntas o preocupaciones sobre nuestra política de privacidad o prácticas de datos, puede contactarnos en: baaxal.desarrollo@gmail.com.</p>
            </section>


            <section>
                <h3>8. Cambios a la Política de Privacidad</h3>
                <p>Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Los cambios entrarán en vigor inmediatamente después de su publicación en nuestro sitio web o software.</p>
            </section>


            <section>
                <h3>9. Legislación Aplicable y Jurisdicción</h3>
                <p>Esta política de privacidad se rige por las leyes de México y cualquier disputa relacionada será sometida a la jurisdicción exclusiva de los tribunales de México.</p>
            </section>











        </div>
    )
}
