import React from 'react'

import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined, YoutubeOutlined } from '@ant-design/icons';

import styles from './Header.module.css'


export const Header = () => {
    return (
        <header className={styles.header}>
            <span className={styles.logo}>Baaxal Studios</span>
            <div className={styles.icons}>
                <FacebookOutlined className={styles.icon} />
                <TwitterOutlined className={styles.icon} />
                <InstagramOutlined className={styles.icon} />
                <LinkedinOutlined className={styles.icon} />
                <YoutubeOutlined className={styles.icon} />
            </div>
        </header>
    );
}

