import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { NotFoundPage } from '../components/NotFoundPage'
import { Layout } from '../components/Layout'


import Home from '../pages/public/Home/Home'
import { PoliticaPrivacidad } from '../pages/public/PoliticaPrivacidad/PoliticaPrivacidad'


export const AppRouter = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Layout></Layout>}>

                  
                    <Route index element={<Home></Home>}></Route>

                    <Route path='*' element={<NotFoundPage></NotFoundPage>}></Route>

                    <Route path='/PoliticasPrivacidad' element={<PoliticaPrivacidad></PoliticaPrivacidad>}></Route>

                </Route>
            </Routes>
        </div>
    )
}


