import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className={styles.footerContainer}>
            <div className={styles.footerColumn}>
                <h3>Acerca de</h3>
                <Link to="/quienes-somos">Quiénes somos</Link>
                <Link to="/historia">Historia</Link>
                <Link to="/equipo">Equipo</Link>
                <Link to="/PoliticasPrivacidad">Términos y Condiciones</Link>
            </div>
            <div className={styles.footerColumn}>
                <h3>Servicios</h3>
                <Link to="/consultoria">Consultoría</Link>
                <Link to="/desarrollo-web">Desarrollo Web</Link>
                <Link to="/marketing-digital">Marketing Digital</Link>
            </div>
            <div className={styles.footerColumn}>
                <h3>Soporte</h3>
                <Link to="/faq">FAQ</Link>
                <Link to="/centro-ayuda">Centro de ayuda</Link>
                <Link to="/contacto">Contacto</Link>
            </div>
            <div className={styles.footerColumn}>
                <h3>Contacto</h3>
                <Link to="/correo-electronico">Correo electrónico</Link>
                <Link to="/ubicacion">Ubicación</Link>
                <Link to="/redes-sociales">Redes sociales</Link>
            </div>
        </footer>
    );
}

export default Footer;
